/*jshint esversion: 5 */
import "../scss/main.scss";

import "leaflet";
import "leaflet.gridlayer.googlemutant";
import "leaflet-routing-machine";
import { mapStyles } from "./components/mapStyles.js";
import * as icons from "./components/icons.js";
import $ from "jquery";
import Cookies from "js-cookie";
import Splide from "@splidejs/splide";
import Hammer from "hammerjs";
import sal from 'sal.js';

const axios = require("axios");

const mapElem = document.getElementById("map");

function createMap() {
  var map = L.map("map", {
    zoomControl: false,
    attributionControl: false,
    scrollWheelZoom: false,
    dragging: false,
    minZoom: 13,
    maxZoom: 18,
    maxBounds: [
      [51.816874, -1.393502],
      [51.717045, -1.127666],
    ],
  }).setView([51.764107, -1.261833], 14);

  var styled = L.gridLayer
    .googleMutant({
      type: "roadmap",
      styles: mapStyles,
    })
    .addTo(map);

  function locate() {
    map.locate({ setView: false, maxZoom: 16 });
  }

  map.on("locationfound", onLocationFound);

  var current_position;

  // call locate every 3 seconds... forever
  setInterval(locate, 3000);
  let venues;
  let iconName;
  let icon;
  let iconHtml;
  let activeGallery;
  let gallery;

  function openVenue(id) {
    closeActive(id);
    gallery = $("[data-id=" + id + "] .venue__gallery");

    $("[data-id=" + id + "]").addClass("active");
    if (gallery.length > 0) {
      activeGallery = new Splide(gallery[0], {
        heightRatio: 1,
        arrows: false,
        cover: true,
        autoplay: true,
        setTimeout: 2000,
        rewind: true,
      });
      activeGallery.mount();
    }
    let pagination = $(".venue__gallery .splide__pagination");
    $(pagination[1]).remove();

    let slides = $(gallery).find(".splide__slide");

    if (slides.length == 1) {
      $(pagination).remove();
    }
  }

  function closeActive(id) {
    let idToClose = $(".venue.active").data("id");
    if (idToClose && idToClose !== id) {
      closeVenue(idToClose);
    }
  }

  function closeVenue(id) {
    $("[data-id=" + id + "]").removeClass("active");
    // if (gallery[0]) {
    //   setTimeout(() => {
    //     activeGallery.destroy(true);
    //     $('.venue__gallery .splide__pagination').remove();
    //   }, 200);
    // }
  }

  let state = {
    posts: [],
    baseUrl: "/wp-json/wp/v2/venue",
    perPage: "?per_page=100",
    wpFetchHeaders: {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Expose-Headers": "x-wp-total",
      },
    },
  };

  axios
    .get(state.baseUrl + state.perPage, {
      headers: state.wpFetchHeaders,
    })
    .then((response) => {
      venues = response.data;
      populateMarkers(venues);
    });

  function populateMarkers(venues) {
    const map_category = document.getElementById("map");

    const map_cat_ar = map_category.dataset.map.split(",");

    venues.forEach((venue) => {
      let display = false;
      console.log(venue);
      map_cat_ar.forEach((map_cat) => {
        venue.map.forEach((venue_cat) => {
          
          if (venue_cat == map_cat) {
            display = true;
          }
        });
      });
      if (venue.categories.length > 1) {
        // var event = {};
        // for (let i = 0; i < 2; i++) {
        //   event[i] = venue.acf.events[i].type.value;

        // }
        // if (event[0] == event[1]) {
        //   iconHtml = `<div class="svg-holder">
        //   ${icons[event[0]]}
        //   </div>`;
        //   iconName = event[0];
        // } else {
        iconHtml = `<div class="svg-combined">
        <div class="svg-container">
          ${venue.categories.map((category) => {
            return (
              '<span class="cat-multiple cat-icon cat-icon-' +
              category +
              '"></span>'
            );
          })}
        </div>
      </div>`;
        // iconName = event[0] + "_" + event[1];
        // }
      } else {
        // var map_event = venue.acf.events[0];
        iconHtml = `<div class="svg-holder">
        <span class="cat-single cat-icon cat-icon-${venue.categories}"></span>
      </div>`;
        iconName = "default";
      }

      //Add Cat support
      var classNameString = "icon-default ";
      if (venue.categories) {
        for (let i = 0; i < venue.categories.length; i++) {
          classNameString += "cat-" + venue.categories[i] + " ";
        }
      }

      icon = L.divIcon({
        // className: "icon-"+iconName+' '+(venue.acf.offset ? 'offset offset-'+venue.acf.offset:''),
        className: classNameString,
        iconSize: 40,
        iconAnchor: [20, 40],
        html: iconHtml,
      });

      if (display) {
        var marker = L.marker(
          [venue.acf.address.lat, venue.acf.address.lng],
          {
            icon: icon,
            title: venue.title.rendered,
            customId: venue.id,
          }
        )
          .addTo(map)
          .on("click", function () {
            $(".leaflet-marker-icon").removeClass("is-active");

            //Send google analytics event.
            ga("send", {
              hitType: "event",
              eventCategory: "Marker",
              eventAction: "open",
              eventLabel: this.options.title,
            });

            dataLayer.push({
              event: "Marker Click",
              marker: this.options.title,
            });

            markerClick(this);
            var el = this.getElement();
            $(el).addClass("is-active");
          });

        marker.bindTooltip(venue.title.rendered);
      }
    });

    function markerClick(e) {
      openVenue(e.options.customId);
    }
  }

  function onLocationFound(e) {
    // if position defined, then remove the existing position marker and accuracy circle from the map
    if (current_position) {
      current_position.setLatLng(e.latlng);
    } else {
      var orangeMarker = L.divIcon({
        className: "icon-you-are-here",
        iconSize: 30,
        iconAnchor: [15, 30],
        html: icons["marker"],
      });
      current_position = L.marker(e.latlng, {
        icon: orangeMarker,
        title: "You are here",
      }).addTo(map);
      current_position.bindTooltip("You are here");
    }
  }

  //Start Intro Interactions.
  $(function () {
    const hideIntro = Cookies.get("DnD");

    //Show intro if DnD cookie is not set.
    if (!hideIntro) {
      $(".intro").fadeIn();
    }

    //Allow intro to be skipped.
    $(".skip-intro").on("click", function () {
      $(".intro").fadeOut();
    });

    //Set the DnD cookie so that the intro is not displayed again. Expires in a year.
    $(".dnd-check").on("click", function () {
      if ($(this).is(":checked")) {
        Cookies.set("DnD", "true", { expires: 365 });
      } else {
        Cookies.remove("DnD");
      }
    });

    //Allow navigation of the intro.
    let imagesSlider;
    let contentSlider;
    let swipeScreens = $(".screen");
    let swipeScreen = {};

    for (let i = 0; i < swipeScreens.length; i++) {
      const swipe = swipeScreens[i];

      swipeScreen[i] = new Hammer(swipe);

      if (i < swipeScreens.length - 1) {
        swipeScreen[i].on("swipeleft", function () {
          switchScreens(i + 2);
        });
      }

      if (i) {
        swipeScreen[i].on("swiperight", function () {
          switchScreens(i);
        });
      }
    }

    $(".list__venue").on("click", function () {
      var id = $(this).data("target");
      openVenue(id);
    });

    function switchScreens(targetNum) {
      var target = $('[data-screen="' + targetNum + '"');
      var screens = $(".screen");
      var dots = $(".dot");
      var imageSlides = $(target).find(".images-slider");
      var contentSlide = $(target).find(".content-slider");

      swipeScreen = new Hammer(target[0]);

      $(screens).removeClass("active");
      $(dots).removeClass("active");

      $(screens).hide();
      $(target).addClass("active");
      $(target).fadeIn();

      if (imagesSlider) {
        imagesSlider.destroy();
        contentSlider.destroy();
      }
      if (imageSlides[0]) {
        imagesSlider = new Splide(imageSlides[0], {
          type: "loop",
          perPage: 3,
          autoWidth: true,
          width: 180,
          perMove: 1,
          arrows: false,
          pagination: false,
          isNavigation: true,
          start: 1,
          focus: "center",
          autoplay: true,
          interval: 5000,
          updateOnMove: true,
          height: 90,
          drag: false,
        }).mount();

        contentSlider = new Splide(contentSlide[0], {
          start: 1,
          type: "fade",
          speed: 1000,
          perPage: 1,
          autoWidth: true,
          width: 280,
          perMove: 1,
          arrows: false,
          pagination: false,
          autoplay: true,
          drag: false,
        });
        contentSlider.sync(imagesSlider).mount();
      }
    }

    $(".screen-nav, .dot").on("click", function () {
      var targetNum = $(this).data("screen-target");
      switchScreens(targetNum);
    });

    $('[data-action="close-popup"]').on("click", function () {
      $(".intro").fadeOut();
    });
    // End Intro Interactions

    //Start Filter Interactions
    $(".filter__open").on("click", function () {
      $(".filter").addClass("active");
    });

    $(".filter__close").on("click", function () {
      $(".filter").removeClass("active");
    });

    $(".toggle__input").on("change", function () {
      const _this = this;
      const val = $(_this).val();

      if (val == "route-walking-route") {
        if ($(this).is(":checked")) {
          route.options.lineOptions.styles[0].opacity = 1;
          $(".toggle__input").not(this).removeAttr("checked");
          $(".toggle__input").not(this).trigger("change");
        } else {
          route.options.lineOptions.styles[0].opacity = 0;
        }
      }

      const icons = $('.leaflet-marker-pane [class*="' + val + '"]');
      const list = $('.list [class*="' + val + '"]');

      $('.leaflet-marker-pane [class*="' + val + '"]').each(function () {
        const icon = $(this);
        const classList = icon[0].classList;
        const categories = [...classList].filter((str) =>
          str.includes("cat-")
        );

        if (categories.length > 1) {
          const category_active = [];
          categories.map((category) => {
            const checkbox = $('.toggle__input[value="' + category + '"');
            if ($(checkbox).is(":checked")) {
              category_active.push(true);
            } else {
              category_active.push(false);
            }
          });

          if (category_active.includes(true)) {
            $(icon).fadeIn();
          } else {
            $(icon).fadeOut();
          }
        } else {
          if ($(_this).is(":checked")) {
            $(icon).fadeIn();
          } else {
            $(icon).fadeOut();
          }
        }
      });

      $('.list [class*="' + val + '"]').each(function () {
        const icon = $(this);
        const classList = icon[0].classList;
        const categories = [...classList].filter((str) =>
          str.includes("cat-")
        );

        if (categories.length > 1) {
          const category_active = [];
          categories.map((category) => {
            const checkbox = $('.toggle__input[value="' + category + '"');
            if ($(checkbox).is(":checked")) {
              category_active.push(true);
            } else {
              category_active.push(false);
            }
          });

          if (category_active.includes(true)) {
            $(icon).fadeIn();
          } else {
            $(icon).fadeOut();
          }
        } else {
          if ($(_this).is(":checked")) {
            $(icon).fadeIn();
          } else {
            $(icon).fadeOut();
          }
        }
      });
    });
    //End Filter Interactions

    $(".radios input").on("click", function () {
      const val = $(this).val();
      if (val == "map") {
        $(".zoom-in, .zoom-out, .drag").show();
        $("#list").hide();
        $("#map").show();
      } else if (val == "list") {
        $(".zoom-in, .zoom-out, .drag").hide();
        $("#map").hide();
        $("#list").show();
      }
    });

    //Start Map Controls
    $(".zoom-in").on("click", function () {
      map.zoomIn();
    });
    $(".zoom-out").on("click", function () {
      map.zoomOut();
    });

    $(".drag").on("click", function () {
      $(this).toggleClass("active");
      if ($(this).hasClass("active")) {
        map.dragging.enable();
      } else {
        map.dragging.disable();
      }
    });
    //End Map Controls

    $(".venue__close").on("click", function () {
      $(".leaflet-marker-icon").removeClass("is-active");
      var id = $(this).data("close");
      closeVenue(id);
    });

    var eventActiveGallery;

    $(".event__title").on("click", function () {
      let parent = $(this).parents(".event");
      let content = $(this).next();
      let event_gallery = $(parent).find(".event__gallery");
      if ($(parent).hasClass("active")) {
        $(content).slideUp();
        $(parent).removeClass("active");
        if (gallery.length > 0) {
          eventActiveGallery.destroy();
        }
      } else {
        $(content).slideDown();
        $(parent).addClass("active");
        if (event_gallery.length > 0) {
          eventActiveGallery = new Splide(event_gallery[0], {
            heightRatio: 1.1,
            arrows: false,
            cover: true,
            autoplay: true,
            setTimeout: 2000,
            rewind: true,
          });
          eventActiveGallery.mount();
        }
      }
    });

    $('[data-target="about"').on("click", function () {
      closeActive("about");
      $(".leaflet-marker-icon").removeClass("is-active");
      $('[data-id="about"').addClass("active");
    });

    var route;

    var routeFormat = new L.Routing.Formatter({
      units: "imperial",
    });

    $(".routes .route").each(function () {
      var waypoints = [];
      var waypointsData = [];

      $(this)
        .find(".route-waypoint")
        .each(function () {
          var lat = $(this).data("lat");
          var lng = $(this).data("lng");
          var title = $(this).data("title");
          var content = $(this).data("content");
          waypoints.push({ latLng: L.latLng(lat, lng), name: title });
          waypointsData.push({ title: title, content: content });
        });

      route = L.Routing.control({
        waypoints: null,
        routeWhileDragging: true,
        lineOptions: {
          styles: [
            {
              color: $(this).data("color"),
              opacity: 1,
              weight: 5,
              dashArray: "1 10",
            },
          ],
          addWaypoints: false,
          missingRouteStyles: [
            {
              color: $(this).data("color"),
              opacity: 0,
              weight: 5,
              dashArray: "1 10",
            },
          ],
        },
        formatter: routeFormat,
        //fitSelectedRoutes: false,
        collapsible: false,
        router: L.Routing.mapbox(
          "pk.eyJ1IjoiYnVyc3RkZXNpZ24iLCJhIjoiY2trMmk4dHJuMGtudTJvb2Jtbnh2bHdubSJ9.fFyh_0Gomuyd-28wUjuRDA",
          { profile: "mapbox/walking" }
        ),
        shown: false,
        createMarker: function (i, start, n) {
          icon = L.divIcon({
            className: "route__marker" + i,
            iconSize: 50,
            // iconAnchor: [25, 50],
            html: `
        <span class="route__marker--icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54">
            <path fill="#414042" d="M27 54A27 27 0 100 27a27 27 0 0027 27"/>
            <path fill="white" d="M18 28.24l6.4-1.5a15.67 15.67 0 010-5c.5-3.71 2.41-10.25-3.2-10.25S14.63 19 15.92 22.2s2.08 6 2.08 6M18.54 30.25l6.4-1.53s1.25 3.73.75 5.17A3 3 0 0121 35.33c-2.08-1.44-2.5-5.08-2.5-5.08M36 34.85l-6.4-1.5a15.72 15.72 0 000-5c-.5-3.7-2.41-10.25 3.2-10.25s6.57 7.53 5.28 10.73S36 34.85 36 34.85M35.46 36.85l-6.4-1.52s-1.25 3.72-.75 5.16A3 3 0 0033 41.94c2.08-1.45 2.5-5.09 2.5-5.09"/>
          </svg>
        </span>`,
            popupAnchor: [0, -25],
          });
          var marker = L.marker(start.latLng, {
            icon: icon,
            title: start.name,
          });

          marker.bindPopup(
            `<h2>${waypointsData[i].title}</h2><p>${waypointsData[i].content}</p>`,
            { className: "route__popup" }
          );
          marker.on("mouseover", function (ev) {
            ev.target.openPopup();
          });
          marker.on("mouseout", function (ev) {
            ev.target.closePopup();
          });
          if (i == n - 1) {
            return null;
          }
          return marker;
        },
      }).addTo(map);

      $(".toggle__input").on("click", function () {
        const _this = this;
        const val = $(_this).val();

        if (val == "route-walking-route") {
          if ($(this).is(":checked")) {
            route.setWaypoints(waypoints);
          } else {
            route.setWaypoints(null);
          }
        }
      });
    });
  });
}

if (mapElem) {
  createMap();
}

sal();
